import { Box, makeStyles, Theme } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    backgroundColor: "var(--c-white)",
    height: 100,
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: "0 0px 15px rgba(0, 0, 0, 0.25)",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

export default function StickyFooter({ children, style }) {
  const classes = useStyles()

  return (
    <Box
      style={style}
      className={classes.root}
      display="flex"
      justifyContent="center"
    >
      <Box flexGrow={1}>{children}</Box>
    </Box>
  )
}
