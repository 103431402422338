import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import React, { Children, useEffect, useState } from "react"
import styled from "styled-components"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import PriceText from "./price-text"
import clsx from "clsx"
import StyledIconButton from "./styled-icon-button"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    border: "1px solid #EEEEEE",
    boxSizing: "border-box",
    borderRadius: 5,
  },
  selected: {
    border: "1px solid var(--c-primary)",
  },
  title: {
    fontSize: theme.typography.h3.fontSize,
  },
  image: {
    width: "30vw",
    height: "30vw",
  },
  price: {
    fontSize: theme.typography.h3.fontSize,
    fontStyle: "normal",
    fontWeight: 300,
    marginTop: theme.spacing(3),
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.typography.body1.fontSize,
  },
  count: {
    marginLeft: 30,
    marginRight: 30,
  },
  details: {
    marginBottom: theme.spacing(2),
  },
  button: {
    border: "1px solid #EEEEEE",
    boxSizing: "border-box",
    borderRadius: 5,
    fontSize: theme.typography.h3.fontSize,
    width: 35,
    height: 35,
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  },
  buttonIcon: {
    width: 20,
    height: 20,
  },
}))

export default function MenuItem({
  id,
  name,
  description,
  options,
  price,
  imgSrc,
  cartCount,
  updateItemCount,
}) {
  const [expanded, setExpanded] = useState(false)

  const classes = useStyles()

  function handleClick(event) {
    event.preventDefault()
    if (cartCount == 0 && !expanded) {
      increment()
    }
    setExpanded(true)
  }
  function decrement() {
    updateItemCount(id, Math.max(cartCount - 1, 0))
  }
  function increment() {
    updateItemCount(id, cartCount + 1)
  }
  function handleClickAway() {
    if (expanded) {
      setExpanded(false)
    }
  }
  useEffect(() => {})
  return (
    <div
      className={
        cartCount > 0
          ? clsx(classes.root, classes.selected)
          : clsx(classes.root)
      }
      id={id}
      onClick={handleClick}
    >
      <div className="section-content-item-main" id={id}>
        <Typography variant="h3">{name.de}</Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="nowrap"
          alignItems="center"
        >
          <Box flexGrow={1}>
            <Typography variant="body1" className={classes.description}>
              {description.de}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {options.de}
            </Typography>
          </Box>
          <Box>{imgSrc && <img className={classes.image} src={imgSrc} />}</Box>
        </Box>
        <p className={classes.price}>
          <PriceText price={price * Math.max(cartCount, 1)} />
        </p>
        {expanded ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box
              className={classes.details}
              display="flex"
              justifyContent="center"
              flexWrap="nowrap"
              alignItems="center"
            >
              <StyledIconButton onClick={decrement}>
                <RemoveIcon />
              </StyledIconButton>
              <Box className={classes.count}>
                <Typography variant="h3">{cartCount}</Typography>
              </Box>
              <StyledIconButton onClick={increment}>
                <AddIcon />
              </StyledIconButton>
            </Box>
          </ClickAwayListener>
        ) : null}
      </div>
    </div>
  )
}
