import React from "react"
import styled from "styled-components"

const Img = styled.img`
  width: 100%;
  height: 133px;
  object-fit: cover;
  object-position: bottom;
`

export default class HeaderImage extends React.Component {
  props: {
    src: string
  }
  render() {
    return <Img src={this.props.src} />
  }
}
