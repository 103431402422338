import {
  AppBar,
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  withStyles,
  Paper,
  Slide,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import React, { Children, useEffect, useState } from "react"
import styled from "styled-components"
import MenuItem from "./menu-item"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import StyledIconButton from "./styled-icon-button"
import PriceText from "./price-text"
import CheckoutCartItem from "./checkout-cart-item"
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied"
import StickyFooter from "./sticky-footer"
import TakeoutDetails from "./checkout-takeout-details"
import firebase from "gatsby-plugin-firebase"
import { Order, OrderStatus, OrderType } from "../interfaces/order"

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    flex: 1,
    textAlign: "center",
    marginRight: theme.spacing(5),
  },
  backButton: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  list: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBotton: theme.spacing(16),
  },
  emptyIcon: {
    width: "10vh",
    height: "10vh",
    marginBottom: theme.spacing(2),
  },
  emptyLabel: {
    marginBottom: theme.spacing(2),
  },
  emptyButton: {
    marginBottom: theme.spacing(2),
  },
  buttonDisabled: {
    background:
      "linear-gradient(90deg, #9240EC 0%, #8E3FED 26.62%, #843FF1 63.99%, #773CF3 98.29%)",
    color: "var(--c-white)",
  },
}))
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

function loadOrder(restaurantId) {
  const orderString = window.sessionStorage.getItem(`order/${restaurantId}`)
  return orderString !== null ? JSON.parse(orderString) : null
}
function saveOrder(restaurantId, order) {
  window.sessionStorage.setItem(`order/${restaurantId}`, JSON.stringify(order))
}

export default function CheckoutCart({
  open,
  handleClose,
  cart,
  updateItemCount,
  itemMap,
  restaurant,
}) {
  const classes = useStyles()
  const [hasMounted, setHasMounted] = React.useState(false)

  const [orderOpen, setOrderOpen] = useState(false)
  const [user, setUser] = useState(null)
  const [orderData, setOrderData] = useState(null)

  function decrement(id) {
    updateItemCount(id, cart.countPerItem[id] - 1)
  }
  function increment(id) {
    updateItemCount(id, cart.countPerItem[id] + 1)
  }
  async function startOrderFlow() {
    if (user) {
      setOrderOpen(true)
      if (orderData && cart.total === orderData.total) {
        // order didn't change, no need to update/create
        console.log("order hasn't changed")
      } else {
        if (orderData) setOrderData(null)
        // create new order
        var order: Order = {
          status: OrderStatus.Checkout,
          type: OrderType.TakeoutDelivery,
          userId: user.uid,
          restaurantId: restaurant.id,
          cart: cart.countPerItem,
          log: [],
        }
        var result = await firebase.functions().httpsCallable("order_create")({
          order: order,
        })
        saveOrder(restaurant.id, result.data)
        setOrderData(result.data)
      }
    } else {
      // TODO: not signed in throw error
      console.log("user not signed in, can't create order")
    }
  }
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      console.log("authChanged")
      setUser(user)
    })

    firebase
      .auth()
      .signInAnonymously()
      .then(() => {
        console.log("signed in")
      })
      .catch(error => {
        var errorCode = error.code
        var errorMessage = error.message
        console.log(error)
      })
  }, [])
  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (hasMounted) {
      const savedOrder = loadOrder(restaurant.id)

      if (savedOrder) {
        setOrderData(savedOrder)
      }
    }
  }, [hasMounted])
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              disableRipple={false}
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIosIcon
                color="primary"
                className={classes.backButton}
              />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Warenkorb
            </Typography>
          </Toolbar>
        </AppBar>
        {cart.total == 0 ? (
          <Box
            margin="auto"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <SentimentVeryDissatisfiedIcon className={classes.emptyIcon} />
            <Typography variant="h3" className={classes.emptyLabel}>
              Dein Warenkorb ist leer
            </Typography>
            <Button
              onClick={handleClose}
              variant="outlined"
              className={classes.emptyButton}
            >
              Zurück zur Karte
            </Button>
          </Box>
        ) : (
          <List className={classes.list}>
            {Object.keys(cart.countPerItem).map(key => {
              return cart.countPerItem[key] > 0 ? (
                <CheckoutCartItem
                  key={key}
                  itemId={key}
                  item={itemMap[key]}
                  itemCount={cart.countPerItem[key]}
                  increment={increment}
                  decrement={decrement}
                />
              ) : null
            })}
          </List>
        )}
        <StickyFooter style={{}}>
          <Button
            variant="contained"
            color="primary"
            onClick={startOrderFlow}
            //classes={{ disabled: classes.buttonDisabled }}
            disabled={cart.total < 1}
          >
            Bestellen
            {cart.total > 0 ? (
              <React.Fragment>
                &nbsp;(
                <PriceText price={cart.total} />)
              </React.Fragment>
            ) : null}
          </Button>
        </StickyFooter>
      </Dialog>
      <TakeoutDetails
        open={orderOpen}
        handleClose={() => {
          setOrderOpen(false)
        }}
        cart={cart}
        restaurant={restaurant}
        orderData={orderData}
      />
    </React.Fragment>
  )
}
