import { Box, Button, makeStyles, Theme, Typography } from "@material-ui/core"
import React, { Children, useState } from "react"
import styled from "styled-components"
import MenuItem from "./menu-item"

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    border: "1px solid #EEEEEE",
    boxSizing: "border-box",
    borderRadius: 5,
    fontSize: theme.typography.h3.fontSize,
    width: 35,
    height: 35,
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  },
  buttonIcon: {
    width: 20,
    height: 20,
  },
}))

export default function StyledIconButton({ children, onClick }) {
  const classes = useStyles()

  return (
    <Button
      color="primary"
      centerRipple={false}
      className={classes.button}
      onClick={onClick}
    >
      {React.cloneElement(children, { className: classes.buttonIcon })}
    </Button>
  )
}
