import React from "react"
import throttle from "lodash/throttle"
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Title from "./header-title"
import { AppBar, Box } from "@material-ui/core"
import HeaderImage from "./header-image"
import MenuSection from "./menu-section"

const tabHeight = 69
interface StyledTabsProps {
  value: number
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void
}

interface StyledTabProps {
  label: string
}

interface StyledTabsProps {
  value: number
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void
}

const StyledTabs = withStyles((theme: Theme) =>
  createStyles({
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      height: "1px",
      "& > span": {
        maxWidth: 40,
        width: "100%",
        backgroundColor: "transparent",
      },
    },
    root: {
      backgroundColor: "var(--c-white)",
      color: "var(--c-almost-black)",
      boxShadow: "1",
    },
  })
)((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span /> }}
    variant="scrollable"
    scrollButtons="auto"
  />
))

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      padding: theme.spacing(2),
      whiteSpace: "nowrap",
      maxWidth: "100vw",
    },
    selected: {
      fontWeight: "bold",
    },
  })
)((props: StyledTabProps) => <Tab {...props} />)

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menubar: {
    boxShadow: "0px 15px 15px 0px rgba(0, 0, 0, 0.25)",
    backgroundColor: "var(--c-white)",
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    width: "100%",
    paddingTop: theme.spacing(1),
  },
  container: {
    margin: theme.spacing(2),
  },
}))

const noop = () => {}

function useThrottledOnScroll(callback, delay) {
  const throttledCallback = React.useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay]
  )

  React.useEffect(() => {
    if (throttledCallback === noop) return undefined

    window.addEventListener("scroll", throttledCallback)
    return () => {
      window.removeEventListener("scroll", throttledCallback)
      throttledCallback.cancel()
    }
  }, [throttledCallback])
}
function prefixSectionId(id) {
  return "section_id_" + id
}

function ScrollSpyTabs(props) {
  const [activeState, setActiveState] = React.useState(null)
  const { restaurant, updateItemCount, cart, items } = props

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveState(newValue)
  }

  let itemsServer = restaurant.menu.sections.map((section, i) => {
    return {
      id: prefixSectionId(i),
      node: () => {
        return document.getElementById(prefixSectionId(i))
      },
    }
  })

  const itemsClientRef = React.useRef([])
  React.useEffect(() => {
    itemsClientRef.current = itemsServer
  }, [itemsServer])

  const clickedRef = React.useRef(false)
  const unsetClickedRef = React.useRef(null)
  const findActiveIndex = React.useCallback(() => {
    // set default if activeState is null
    if (activeState === null) setActiveState(itemsServer[0].id)

    // Don't set the active index based on scroll if a link was just clicked
    if (clickedRef.current) return

    let active
    for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      if (document.documentElement.scrollTop < 0) {
        active = { id: null }
        break
      }

      const item = itemsClientRef.current[i]

      if (
        item.node() &&
        item.node().offsetTop <
          document.documentElement.scrollTop +
            document.documentElement.clientHeight / 8 +
            tabHeight
      ) {
        active = item
        break
      }
    }

    if (active && activeState !== active.id) {
      setActiveState(active.id)
    }
  }, [activeState, itemsServer])

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(itemsServer.length > 0 ? findActiveIndex : null, 166)

  const handleClick = id => () => {
    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false
    }, 1000)

    if (activeState !== id) {
      setActiveState(id)

      if (window)
        window.scrollTo({
          top:
            document.getElementById(id).getBoundingClientRect().top +
            window.pageYOffset -
            110,
          behavior: "smooth",
        })
    }
  }

  React.useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current)
    },
    []
  )

  const classes = useStyles()

  var tabs = React.useMemo(() => {
    return (restaurant.menu.sections as []).map((section, i) => {
      return (
        <StyledTab
          key={prefixSectionId(i)}
          label={section.name.de}
          onClick={handleClick(prefixSectionId(i))}
          value={prefixSectionId(i)}
        />
      )
    })
  }, restaurant)

  return (
    <Box>
      <HeaderImage src={restaurant.banner} />
      <Box className={classes.menubar}>
        <Title>{restaurant.name.de}</Title>
        <StyledTabs
          value={
            activeState
              ? activeState
              : itemsServer[0]
              ? itemsServer[0].id
              : null
          }
          onChange={handleChange}
        >
          {tabs}
        </StyledTabs>
      </Box>

      <Box className={classes.container}>
        {restaurant.menu.sections.map((section, i) => {
          return (
            <MenuSection
              id={prefixSectionId(i)}
              key={prefixSectionId(i)}
              section={section}
              cart={cart}
              items={items}
              updateItemCount={updateItemCount}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default ScrollSpyTabs
