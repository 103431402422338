import React, { Component, useRef } from "react"
import { Grid, TextField, Typography } from "@material-ui/core"
import throttle from "lodash/throttle"
import Autocomplete from "@material-ui/lab/Autocomplete"
import parse from "autosuggest-highlight/parse"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { makeStyles } from "@material-ui/core/styles"

const autocompleteService = { current: null }

export interface PlaceType {
  description: string
  place_id: string
  structured_formatting: {
    main_text: string
    secondary_text: string
    main_text_matched_substrings: [
      {
        offset: number
        length: number
      }
    ]
  }
}
const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

export default function AddressAutocomplete({ value, setValue, setEditingForm }) {
  const classes = useStyles()
  const [inputValue, setInputValue] = React.useState("")
  const [options, setOptions] = React.useState<PlaceType[]>([])
  const loaded = React.useRef(false)

  // if (typeof window !== 'undefined' && !loaded.current) {
  //   if (!document.querySelector('#google-maps')) {
  //     loadScript(
  //       'https://maps.googleapis.com/maps/api/js?key=AIzaSyBwRp1e12ec1vOTtGiA4fcCt2sCUS78UYc&libraries=places',
  //       document.querySelector('head'),
  //       'google-maps',
  //     );
  //   }

  //   loaded.current = true;
  // }

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: PlaceType[]) => void
        ) => {
          ;(autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          )
        },
        200
      ),
    []
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === "") {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[]

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])
  const fieldRef = useRef();
  return (
    <Autocomplete
      id="address"
      getOptionLabel={option =>
        typeof option === "string" ? option : option.description
      }
      onFocus={() => {setEditingForm(true)}}
      onBlur={() => {setEditingForm(false)}}
      filterOptions={x => x}
      options={options}
      noOptionsText=""
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
        setEditingForm(false)
        fieldRef.current.blur()
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={params => (
        <TextField
          inputRef={fieldRef}
          {...params}
          label="Lieferadresse"
          variant="outlined"
          fullWidth={true}
        />
      )}
      renderOption={option => {
        const matches =
          option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        )

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}

{
  /* <TextField
                        id="address"
                        label="Lieferadresse"
                        type="text"
                        autoComplete="street-address"
                        variant="outlined"
                        fullWidth={true}                        
        value={this.state.value}
        onChange={this._handleChange}
                        /> */
}
