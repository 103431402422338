import {
  AppBar,
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  withStyles,
  Paper,
  Slide,
  Theme,
  Toolbar,
  Typography,
  Input,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import React, { Children, useEffect, useState } from "react"
import styled from "styled-components"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import StyledIconButton from "./styled-icon-button"
import PriceText from "./price-text"
import CheckoutCartItem from "./checkout-cart-item"
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied"
import StickyFooter from "./sticky-footer"
import AddressAutocomplete, { PlaceType } from "./address-autocomplete"
import firebase from "gatsby-plugin-firebase"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import PaymentButton from "./payment-button"
import { OrderTakeout } from "../interfaces/order"

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    flex: 1,
    textAlign: "center",
    marginRight: theme.spacing(5),
  },
  backButton: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  addressBox: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  addressTextField: {
    marginRight: theme.spacing(1),
  },
  formRow: {
    marginTop: theme.spacing(3),
  },
  saveDataSelect: {
    marginLeft: theme.spacing(2),
  },
  timeSelectorItemSelected: {
    backgroundColor: "transparent !important",
  },
}))
const popoverStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
  },
}))

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const stripePromise = loadStripe("pk_test_4VxqFEaRB2VeIGpZxvPlOhkm", {
  apiVersion: "2020-08-27",
})

function loadTakeout(storage, restaurantId) {
  const takeoutString = storage.getItem(`takeout/${restaurantId}`)
  return takeoutString !== null ? JSON.parse(takeoutString) : null
}
function wipeTakeout(storage, restaurantId) {
  storage.removeItem(`takeout/${restaurantId}`)
}
function saveTakeout(storage, restaurantId, takeout) {
  storage.setItem(`takeout/${restaurantId}`, JSON.stringify(takeout))
}

export default function TakeoutDetails({
  open,
  handleClose,
  cart,
  restaurant,
  orderData,
}) {
  const classes = useStyles()
  const popoverClasses = popoverStyles()
  const [hasMounted, setHasMounted] = React.useState(false)

  const [familyname, setFamilyname] = useState("")
  const [address, setAddress] = useState<PlaceType | null>(null)
  const [phone, setPhone] = useState("")
  const [time, setTime] = useState(-1)
  const [saveData, setSaveData] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [editingForm, setEditingForm] = useState(false)

  async function confirmOrder() {
    var takeout: OrderTakeout = {
      rememberData: saveData,
      customerName: familyname,
      customerPhone: phone,
      address: {
        unstructured: address.description,
        gmaps_placeid: address.place_id,
        source: address,
      },
    }
    console.log(takeout)

    // save data locally
    if (takeout.rememberData) {
      saveTakeout(window.localStorage, restaurant.id, takeout)
    } else {
      // we still want to keep this in the session in-case payment fails to prevent having to reenter the data
      saveTakeout(window.sessionStorage, restaurant.id, takeout)

      // wipe any data we might have persisted previously
      wipeTakeout(window.localStorage, restaurant.id)
    }
    var result = await firebase.functions().httpsCallable("order_setTakeout")({
      orderId: orderData.id,
      takeout: takeout,
    })
    if (!result.data) {
      // TODO: show error message
      console.log("error setting takeout data")
    }
    console.log(result)
  }

  function isFormValid() {
    // TODO: replace dummy validator
    return (
      familyname.length > 0 && address && address.place_id && phone.length > 8
    )
  }

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (hasMounted) {
      const sessionTakeout = loadTakeout(window.sessionStorage, restaurant.id)
      const persistentTakeout = loadTakeout(window.localStorage, restaurant.id)

      const takeout = sessionTakeout || persistentTakeout

      if (takeout) {
        setFamilyname(takeout.customerName)
        setPhone(takeout.customerPhone)
        setAddress(takeout.address.source)
        setSaveData(takeout.rememberData)
      }
    }
  }, [hasMounted])

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            disableRipple={false}
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <ArrowBackIosIcon color="primary" className={classes.backButton} />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Bestellung
          </Typography>
        </Toolbar>
      </AppBar>
      <Box className={classes.addressBox}>
        <Typography variant="h3">Details zur Lieferung</Typography>
        <form>
          <TextField
            className={classes.formRow}
            id="lastname"
            label="Nachname"
            type="text"
            autoComplete="family-name"
            variant="outlined"
            fullWidth={true}
            value={familyname}
            onChange={e => {
              setFamilyname(e.target.value)
            }}
            onFocus={() => {setEditingForm(true)}}
            onBlur={() => {setEditingForm(false)}}
          />

          <Box className={classes.formRow}>
            <AddressAutocomplete value={address} setValue={setAddress} setEditingForm={setEditingForm} />
          </Box>

          <TextField
            id="phone"
            label="Handynummer"
            type="tel"
            autoComplete="tel"
            variant="outlined"
            fullWidth={true}
            className={classes.formRow}
            value={phone}
            onChange={e => {
              setPhone(e.target.value)
            }}
            onFocus={() => {setEditingForm(true)}}
            onBlur={() => {setEditingForm(false)}}
          />
          <Box display="flex" className={classes.formRow}>
            {
              // TODO: enable time picking
              /* <FormControl component="div" variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Uhrzeit
              </InputLabel>

              <Select
                labelId="time-label"
                id="time"
                variant="outlined"
                value={time}
                onChange={e => {
                  setTime(e.target.value)
                }}
                label="Uhrzeit"
                MenuProps={{PopoverClasses: popoverClasses}}
              >
                <MenuItem value={-1}>
                  <em>Sofort</em>
                </MenuItem>
                <MenuItem value={1015}>10:15</MenuItem>
                <MenuItem value={1030}>10:30</MenuItem>
                <MenuItem value={1045}>10:35</MenuItem>
              </Select>
            </FormControl> */
            }
            <FormControl
              component="div"
              variant="outlined"
              className={classes.saveDataSelect}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={saveData}
                    onChange={e => {
                      setSaveData(e.target.checked)
                    }}
                    name="save_data"
                    color="primary"
                  />
                }
                label="Eingaben für die nächste Bestellung merken"
              />
            </FormControl>
          </Box>
        </form>
      </Box>

      <Elements stripe={stripePromise}>
        <StickyFooter style={{ display: !editingForm && isFormValid() ? "block" : "none" }}>
          <Box onClick={confirmOrder}>
            <PaymentButton
              cart={cart}
              restaurant={restaurant}
              orderData={orderData}
            />
          </Box>
        </StickyFooter>
      </Elements>
    </Dialog>
  )
}
