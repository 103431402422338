import React, { useEffect, useState, useReducer } from "react"
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import AppBar from "@material-ui/core/AppBar"
import { useRef } from "react"
import { shadows } from "@material-ui/system"
import Title from "./header-title"
import { Link, scrollSpy } from "react-scroll"
import VizSensor from "react-visibility-sensor"
import ScrollSpyTabs from "./scrollspy-tabs"
import MenuItem from "./menu-item"
import { Button, Paper, Slide, Typography } from "@material-ui/core"
import StickyFooter from "./sticky-footer"
import PriceText from "./price-text"
import CheckoutCart from "./checkout-cart"
import { TransitionProps } from "@material-ui/core/transitions/transition"

const useStyles = makeStyles((theme: Theme) => ({}))

function loadCart(restaurantId) {
  const cartString = window.localStorage.getItem(`cart/${restaurantId}`)
  return cartString !== null ? JSON.parse(cartString) : null
}
function saveCart(restaurantId, cart) {
  window.localStorage.setItem(`cart/${restaurantId}`, JSON.stringify(cart))
}

export default function Menu(props) {
  const { restaurant } = props
  var items = React.useMemo(() => {
    return restaurant.menu.items.reduce(
      (map, val) => ({ ...map, [val.id]: val }),
      {}
    )
  }, restaurant)

  function cartReducer(cart, update: { id: string; count: number }) {
    var countPerItem = { ...cart.countPerItem, [update.id]: update.count }
    var total = 0
    for (var key in countPerItem) {
      total += items[key].price * countPerItem[key]
    }
    return {
      total: total,
      countPerItem: countPerItem,
    }
  }
  const [cart, setCart] = useReducer(cartReducer, {
    total: 0,
    countPerItem: {},
  })
  const [hasMounted, setHasMounted] = React.useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (hasMounted) {
      const savedCart = loadCart(restaurant.id)
      if (savedCart) {
        Object.keys(savedCart.countPerItem).forEach(id => {
          setCart({ id: id, count: savedCart.countPerItem[id] })
        })
      }
    }
  }, [hasMounted])

  useEffect(() => {
    if (hasMounted) {
      saveCart(restaurant.id, cart)
    }
  }, [cart])

  const [checkoutOpen, setCheckoutOpen] = useState(false)

  const classes = useStyles()

  useEffect(() => {})

  function updateItemCount(id, count) {
    setCart({ id: id, count: count })
  }
  function handleCheckoutOpen() {
    setCheckoutOpen(true)
  }
  function handleCheckoutClose() {
    setCheckoutOpen(false)
  }

  return (
    <div>
      <ScrollSpyTabs
        updateItemCount={updateItemCount}
        restaurant={restaurant}
        cart={cart}
        items={items}
      />
      {cart.total > 0 ? (
        <StickyFooter>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCheckoutOpen}
          >
            Warenkorb&nbsp;(
            <PriceText price={cart.total} />)
          </Button>
        </StickyFooter>
      ) : null}
      <CheckoutCart
        open={checkoutOpen}
        handleClose={handleCheckoutClose}
        cart={cart}
        updateItemCount={updateItemCount}
        itemMap={items}
        restaurant={restaurant}
      />
    </div>
  )
}
