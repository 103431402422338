import {
  AppBar,
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Slide,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import React, { Children, useState } from "react"
import styled from "styled-components"
import MenuItem from "./menu-item"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import StyledIconButton from "./styled-icon-button"
import PriceText from "./price-text"

const useStyles = makeStyles((theme: Theme) => ({
  cartItem: {
    padding: 0,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  textBox: {
    margin: theme.spacing(1),
  },
  countBox: {
    marginRight: theme.spacing(4),
  },
  descriptionBox: {
    marginRight: theme.spacing(4),
  },
  priceBox: {},
  actionBox: {
    margin: 0,
    marginTop: theme.spacing(1),
  },
  commentButton: {
    fontWeight: 600,
    textTransform: "none",
    color: "rgba(95, 0, 210, 0.3)",
    padding: theme.spacing(1),
  },
  controlButton: {
    marginLeft: theme.spacing(1),
    display: "inline",
  },
}))
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function CheckoutCartItem({
  itemId,
  item,
  itemCount,
  increment,
  decrement,
}) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <ListItem className={classes.cartItem}>
        <Box width="100%">
          <Box
            className={classes.textBox}
            display="flex"
            justifyContent="space-between"
          >
            <Box className={classes.countBox}>
              <Typography variant="h3">{itemCount}x</Typography>
            </Box>
            <Box flexGrow={1} className={classes.descriptionBox}>
              <Typography variant="h3">{item.name.de}</Typography>
            </Box>
            <Box className={classes.priceBox}>
              <Typography variant="h3" noWrap={true}>
                <PriceText price={itemCount * item.price} />
              </Typography>
            </Box>
          </Box>

          <Box
            className={classes.actionBox}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
          >
            <Box>
              <Button className={classes.commentButton}>
                Anmerkung hinzufügen
              </Button>
            </Box>
            <Box className={classes.priceBox} whiteSpace="nowrap">
              <Box className={classes.controlButton}>
                <StyledIconButton
                  onClick={() => {
                    decrement(itemId)
                  }}
                >
                  <RemoveIcon />
                </StyledIconButton>
              </Box>
              <Box className={classes.controlButton}>
                <StyledIconButton
                  onClick={() => {
                    increment(itemId)
                  }}
                >
                  <AddIcon />
                </StyledIconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </ListItem>
      <Divider />
    </React.Fragment>
  )
}
