import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HeaderImage from "../components/header-image"
import Menu from "../components/menu"
import {} from "@material-ui/core"
import FBTest from "../components/firebase-test"
import firebase from "gatsby-plugin-firebase"
import { Helmet } from "react-helmet"

var googlePlacesApiKey = "AIzaSyDxDkP2EfeNRjOkLyhGDK9u3Uf_JJ2Foso"

export default function Home({ data }) {
  return (
    <Layout>
      <Helmet>
        <script
          type="text/javascript"
          src={`https://maps.googleapis.com/maps/api/js?key=${googlePlacesApiKey}&libraries=places`}
        ></script>
      </Helmet>
      <Menu restaurant={data.allRestaurant.nodes[0]}></Menu>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    allRestaurant(filter: { id: { eq: $id } }) {
      nodes {
        id
        path
        name {
          de
        }
        banner
        address
        menu {
          sections {
            name {
              de
            }
            items
          }
          items {
            id
            name {
              de
            }
            price
            description {
              de
            }
            choice {
              de
            }
          }
        }
      }
    }
  }
`
