import React, { useState, useEffect } from "react"
import { PaymentRequestButtonElement, useStripe } from "@stripe/react-stripe-js"
import { Box, Button, CircularProgress } from "@material-ui/core"
import PriceText from "./price-text"
import firebase from "gatsby-plugin-firebase"
import {
  PaymentIntent,
  PaymentRequestPaymentMethodEvent,
} from "@stripe/stripe-js"

export default function PaymentButton({ cart, restaurant, orderData }) {
  const stripe = useStripe()
  const [paymentRequest, setPaymentRequest] = useState(null)

  function handleLegacyPayment() {
    stripe
      .redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as argument here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: orderData.checkoutSessionId,
      })
      .then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      })
  }
  function paymentPaymentError() {}
  async function handlePayment(ev: PaymentRequestPaymentMethodEvent) {
    if (!orderData) {
      console.log("got no orderData")
      return
    }
    console.log("got orderData")
    // Confirm the PaymentIntent without handling potential next actions (yet).
    const {
      paymentIntent,
      error: confirmError,
    } = await stripe.confirmCardPayment(
      orderData.clientSecret,
      { payment_method: ev.paymentMethod.id },
      { handleActions: false }
    )

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      console.log("payment failed")
      ev.complete("fail")
      paymentPaymentError()
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete("success")
      console.log("handling payment intent")
      console.log(paymentIntent)
      console.log(orderData)
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11" instead
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(
          orderData.clientSecret
        )
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          console.log("payment failed")
          paymentPaymentError()
        } else {
          // The payment has succeeded.
          console.log("success we haz moneys")
          window.location.href = "../status/?o=" + orderData.id
        }
      } else {
        console.log("success we haz moneys")
        window.location.href = "../status/?o=" + orderData.id
      }
    }
  }
  useEffect(() => {
    if (stripe) {
      console.log("creating pr")
      const pr = stripe.paymentRequest({
        country: "DE",
        currency: "eur",
        total: {
          label: "Bestellung bei " + restaurant.name.de,
          amount: cart.total,
        },
        requestPayerName: false,
        requestPayerEmail: false,
      })
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr)
        } else {
          console.log(result)
        }
      })
    }
  }, [stripe])
  useEffect(() => {
    if (orderData && paymentRequest) {
      console.log("subscribing to payment event")
      const _ = paymentRequest.on("paymentmethod", handlePayment)
    }
  }, [orderData, paymentRequest])

  if (paymentRequest) {
    return <PaymentRequestButtonElement options={{ paymentRequest }} />
  } else if (orderData && orderData.checkoutSessionId) {
    return (
      <Button variant="contained" color="primary" onClick={handleLegacyPayment}>
        Bestellen
        {cart.total > 0 ? (
          <React.Fragment>
            &nbsp;(
            <PriceText price={cart.total} />)
          </React.Fragment>
        ) : null}
      </Button>
    )
  } else {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  // Use a traditional checkout form.
  //   return (
  //     <Button
  //     variant="contained"
  //     color="primary"
  //     //disabled={cart.total < 1}
  //   >
  //     Bestellen
  //     {cart.total > 0 ? (
  //       <React.Fragment>
  //         &nbsp;(
  //         <PriceText price={cart.total} />)
  //       </React.Fragment>
  //     ) : null}
  //   </Button>
  //   )
}
