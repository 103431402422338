import { makeStyles, Theme, Typography, withStyles } from "@material-ui/core"
import React, { Children } from "react"
import styled from "styled-components"
import { useTheme, withTheme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    marginright: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}))

export default function Title({ children }) {
  const classes = useStyles(useTheme())

  return (
    <Typography variant="h1" className={classes.title}>
      {children}
    </Typography>
  )
}
