import { Box, makeStyles, Theme, Typography } from "@material-ui/core"
import React, { Children, useState } from "react"
import styled from "styled-components"
import MenuItem from "./menu-item"

const useStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
}))

export default function MenuSection({
  id,
  section,
  items,
  updateItemCount,
  cart,
}) {
  const classes = useStyles()
  const itemComps = section.items.map((itemId, i) => {
    var item = items[itemId]

    return React.useMemo(() => {
      return (
        <MenuItem
          id={itemId}
          key={itemId}
          name={item.name}
          description={item.description ? item.description : ""}
          options={item.choice ? item.choice : ""}
          price={item.price}
          imgSrc={item.imageUrl}
          cartCount={cart.countPerItem[itemId] ? cart.countPerItem[itemId] : 0}
          updateItemCount={updateItemCount}
        ></MenuItem>
      )
    }, [cart.countPerItem[itemId] ? cart.countPerItem[itemId] : 0])
  })
  return (
    <section id={id} key={id}>
      <Typography className={classes.sectionTitle} key={id} variant="h2">
        {section.name.de}
      </Typography>
      {itemComps}
    </section>
  )
}
